import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import {
  BindContext,
  ERPSignalRConnectionService,
  ERPUtilsService,
  IDestroyable,
  ISignalRMessage,
  ISignalRMessageBody
} from '@erp/shared';

import { ERPToasterService } from '../../toaster';
import { NotificationType } from '../enums';
import { IDocNotificationContext, INcpNotificationContext, INotification, ISignalRNotification } from '../interfaces';
import { ERPNotificationEventsService } from '../services/notification-events.service';

import { ERPNotificationUtilityService } from './notification-utility.service';
import { ERPNotificationService } from './notification.service';

const NOTIFICATION_MESSAGE_TYPE = 'Notification';

@Injectable({
  providedIn: 'root'
})
export class ERPNotificationListenerService implements IDestroyable, OnDestroy {
  destroyed$ = new Subject<void>();

  constructor(
    private readonly toaster: ERPToasterService,
    private readonly signalR: ERPSignalRConnectionService,
    private readonly eventsService: ERPNotificationEventsService,
    private readonly notificationUtility: ERPNotificationUtilityService
  ) {}

  initListener() {
    this.signalR.messages$
      .pipe(
        takeUntil(this.destroyed$),
        map(message => message as ISignalRMessage),
        filter(message => message.Metadata.MessageType === NOTIFICATION_MESSAGE_TYPE),
        map(message => this.toNotification(message.MessageBody)),
        tap(notification => this.eventsService.addNotification(notification))
      )
      .subscribe(notification => {
        const config = { onClick: () => this.notificationUtility.openNotificationDocument(notification) };
        switch (notification.typeId) {
          case NotificationType.Created:
            this.toaster.success(
              {
                title: $localize`:@@notifications.toast.created.title:Item Created`,
                message: notification.title
              },
              null,
              config
            );
            break;
          case NotificationType.Updated:
            this.toaster.warn(
              {
                title: $localize`:@@notifications.toast.updated.title:Item Updated`,
                message: notification.title
              },
              null,
              config
            );
            break;
          case NotificationType.Information:
            this.toaster.info(
              {
                title: $localize`:@@notifications.toast.assignment.title:New Assignment`,
                message: notification.title
              },
              null,
              config
            );
            break;
          case NotificationType.Deleted:
            this.toaster.error({
              title: $localize`:@@notifications.toast.deleted.title:Item Deleted`,
              message: notification.title
            });
          case NotificationType.SalesWorkflow:
            this.toaster.success(
              {
                title: $localize`:@@notifications.toast.assignment.title:Sales Workflow`,
                message: notification.title
              },
              null,
              config
            );
            break;
        }
      });
  }

  toNotification(messageBody: object | ISignalRMessageBody): INotification {
    const notification = messageBody as ISignalRNotification;
    const { itemId, itemTypeId, itemNumber } = (notification.context as IDocNotificationContext).references?.[0] || {};

    return {
      id: notification.id,
      date: notification.date,
      fromUserId: notification.fromUserId,
      fromUserName: notification.fromUserName,
      isRead: notification.isRead,
      title: notification.title,
      typeId: notification.category,
      referenceItemId: itemId,
      referenceItemTypeId: +itemTypeId,
      referenceItemNumber: itemNumber,
      annotation: (notification.context as INcpNotificationContext).annotation
    };
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
