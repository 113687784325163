import { of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BindContext, ERPAuthService, ERPUtilsService, IDestroyable, NcpType } from '@erp/shared';

import { INcpNotificationAnnotation, INotification } from '../interfaces';
import { ERPNotificationEventsService } from '../services/notification-events.service';

import { ERPNotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ERPNotificationUtilityService implements IDestroyable, OnDestroy {
  destroyed$ = new Subject<void>();

  constructor(
    private readonly utilsService: ERPUtilsService,
    private readonly router: Router,
    private readonly notificationService: ERPNotificationService,
    private readonly authService: ERPAuthService,
    private readonly notificationEvents: ERPNotificationEventsService,
    private readonly ngZone: NgZone
  ) {}

  @BindContext(this)
  openNotificationDocument(element: INotification) {
    const isTablet = this.router.url.startsWith('/tablet');

    const { referenceItemTypeId } = element;
    let { referenceItemId } = element;
    const uriPattern = this.utilsService.getUriByDocumentID(referenceItemTypeId, isTablet);
    let uri = uriPattern.replace('{id}', referenceItemId?.toString());
    let params = {};

    if (element.annotation) {
      params = this.getNcpParams(element.annotation);
      uri = this.getNcpUri(element.annotation);
      referenceItemId = element.annotation?.processingOrderId;
    }

    if (element.isRead) {
      this.navigateTo(referenceItemId, uri, params);

      return;
    }

    this.setReadState$(element, true)
      .pipe(
        catchError(error => {
          console.error('Failed to mark notification as read', error);
          return of(null);
        })
      )
      .subscribe(() => this.navigateTo(referenceItemId, uri, params));
  }

  setReadState$(element: INotification, isRead: boolean) {
    const userId = this.authService.getCustomUserID();

    return this.notificationService.markReadState(userId, element.id, isRead).pipe(
      takeUntil(this.destroyed$),
      tap(() => {
        const newCount = isRead ? this.notificationEvents.unreadCount - 1 : this.notificationEvents.unreadCount + 1;
        this.notificationEvents.updateUnreadCount(newCount);
      })
    );
  }

  private navigateTo(documentId: number | undefined, uri: string, queryParams: object) {
    if (documentId) {
      this.ngZone.run(() => this.router.navigate([uri], { queryParams }));
    }
  }

  private getNcpParams(annotation: INcpNotificationAnnotation): object {
    const { skuId, dispositionName, heatNumber, isInternal, ncpDefectId, ncpTypeId } = annotation;

    return { skuId, dispositionName, heatNumber, isInternal, ncpDefectId, ncpTypeId };
  }

  private getNcpUri(annotation: INcpNotificationAnnotation): string {
    const { processingOrderId, processingServiceId, scheduleEventId, ncpTypeId } = annotation;
    const ncpType = ncpTypeId === NcpType.Reject ? 'reject' : 'rework';

    return `tablet/production/processing-order/${processingOrderId}/service/${processingServiceId}/event/${scheduleEventId}/quality-control/${ncpType}`;
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
