import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

import { ERPTableFilterOpener, ERPToasterService, ISelectOption } from '../../';
import {
  AutoCleanupFeature,
  AvailabilityType,
  BaseEditableTableComponent,
  DictionaryService,
  ERPFormStateDispatcher,
  ERPQueryingService,
  ERPUomService,
  ERPUtilsService,
  Features,
  IInventoryUom,
  ILineMaterial,
  ISalesOrderLineMaterial,
  IStockMaterial,
  Memoise,
  ModulesLinks,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';

import { ERPSalesQuoteMaterialsAllocatedMaterialComponent } from '../sales-quote-materials-allocated-material/sales-quote-materials-allocated-material.component';
import { ILineFilterData } from '../interfaces/allocated-materails-dialog-data.interface';
import { catchError, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'erp-sales-materials-available-materials-table',
  templateUrl: './materials-available-materials-table.component.html',
  styleUrls: ['./materials-available-materials-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPMaterialsAvailableMaterialsTableComponent
  extends BaseEditableTableComponent<IStockMaterial>
  implements OnInit, AfterContentInit
{
  constructor(
    readonly changeDetector: ChangeDetectorRef,
    readonly tableFilterOpener: ERPTableFilterOpener,
    readonly formState: ERPFormStateDispatcher,
    readonly toaster: ERPToasterService,
    readonly dialog: MatDialog,
    readonly queryingService: ERPQueryingService,
    readonly router: Router,
    readonly utilsService: ERPUtilsService,
    readonly dictionaryService: DictionaryService
  ) {
    super();
  }
  @Input() readonly uoms: IInventoryUom[];
  @Input() readonly selectedMaterials: ILineMaterial[];
  @Input() readonly lineFilter: ILineFilterData;
  @Input() readonly showInventory: boolean;
  @Input() readonly inventoryStatuses: ISelectOption[];

  @Output() readonly materialSelected = new EventEmitter<IStockMaterial>();
  @Output() readonly materialReservationChanged = new EventEmitter<IStockMaterial>();

  @ViewChild('GEN49', { static: true }) readonly GEN49: TemplateRef<unknown>;

  columns: (keyof IStockMaterial | 'actions')[] = [
    'materialType',
    'sku',
    'skuDescription',
    'material',
    'mill',
    'processor',
    'receiptDate',
    'qtyClaims',
    'qltClaims',
    'ownerName',
    'site',
    'availabilityType',
    'availableQtyEa',
    'availableQtyFt',
    'allocatedEA',
    'allocatedFT',
    'processingDate',
    'entryDocumentNumber',
    'classification',
    'vesselName',
    'costEA',
    'cost',
    'qty',
    'notes',
    'actions'
  ];
  readonly salesLinks = ModulesLinks;
  readonly links = new Map<string, string>([
    ['CAS', ModulesLinks.Casing],
    ['TUB', ModulesLinks.Tubing],
    ['COS', ModulesLinks.CouplingStock],
    ['LIP', ModulesLinks.LinePipe],
    ['DRP', ModulesLinks.DrillPipe],
    ['MAR', ModulesLinks.Marker],
    ['COU', ModulesLinks.Coupling],
    ['PRO', ModulesLinks.Protector],
    ['SER', ModulesLinks.Service]
  ]);
  readonly transactionLinks = new Map<string, string>([
    ['PO', ModulesLinks.PurchaseOrders],
    ['PR', ModulesLinks.PurchaseRequisitions],
    ['POR', ModulesLinks.Receipts],
    ['IDL', ModulesLinks.InboundDeliveries]
  ]);
  availabilityTypes = [
    { id: AvailabilityType['On Hand'], value: AvailabilityType['On Hand'] },
    { id: AvailabilityType.Expected, value: AvailabilityType.Expected },
    { id: AvailabilityType.MIP, value: AvailabilityType.MIP }
  ];

  readonly viewToModelParser = () => this.form.getRawValue();

  readonly modelToViewFormatter = (materials: IStockMaterial[]) => {
    this.dataSource.data = [...materials.keys()];

    return materials.map(material => this.createLine(material));
  };

  readonly uomLabelFn = (uom: IInventoryUom) => `${uom.symbol} (${uom.value})`;
  readonly uomDisplayFn = (uoms: IInventoryUom[], id: number) => uoms.find(x => x.id === id)?.symbol as string;

  @Memoise()
  get salesDocumentTypes$(): Observable<ISelectOption[]> {
    return this.dictionaryService.salesDocumentTypes;
  }

  ngOnInit() {
    if (this.showInventory) {
      this.columns.splice(
        this.columns.findIndex(i => i === 'mill'),
        0,
        'inventoryStatus'
      );
    }
    this.onInit();
  }

  onViewMaterials(index: number) {
    const selectedAvailableMaterial = this.form.at(index)?.value as IStockMaterial;

    this.salesDocumentTypes$.pipe(catchError(() => of([]))).subscribe(salesDocumentTypes => {
      this.dialog.open(ERPSalesQuoteMaterialsAllocatedMaterialComponent, {
        data: { selectedAvailableMaterial, line: this.lineFilter, salesDocumentTypes }
      });
    });
  }

  ngAfterContentInit() {
    this.changeDetector.markForCheck();
  }

  onSelectMaterial(index: number) {
    const control = this.form.at(index) as UntypedFormGroup;
    const material = control.getRawValue() as IStockMaterial;

    this.editableRow = -1;
    this.materialSelected.emit(material);
  }

  onEditMaterial(index: number) {
    if (this.form.at(this.editableRow)?.invalid) {
      return this.formState.onSubmit.notify();
    }

    this.editableRow = index;
    this.changeDetector.markForCheck();
  }

  onClickLine(index: number) {
    if (index === this.editableRow) {
      return;
    }

    if (this.form.at(this.editableRow)?.invalid) {
      return this.formState.onSubmit.notify();
    }

    this.editableRow = -1;
  }

  onReservedQtyChanged(index: number) {
    const control = this.form.at(index) as UntypedFormGroup;
    const material = control.getRawValue() as IStockMaterial;
    const { available, qty } = material;

    if ((qty as number) > available) {
      this.toaster.warn(
        {
          template: this.GEN49
        },
        null,
        {
          closeOnNavigate: true
        }
      );
    }

    this.materialReservationChanged.emit(material);
  }

  onRebuildTable() {
    const value = this.form.getRawValue();
    this.dataSource.data = this.queryingService.getIndexes<IStockMaterial>(value, {
      filtering: this.localFiltering,
      sorting: this.localSorting
    });
    this.changeDetector.markForCheck();
  }

  createLine(material: IStockMaterial) {
    return new UntypedFormGroup({
      notes: new UntypedFormControl(material.notes),
      materialType: new UntypedFormControl(material.materialType),
      sku: new UntypedFormControl(material.sku),
      skuDescription: new UntypedFormControl(material.skuDescription),
      material: new UntypedFormControl(material.material),
      mill: new UntypedFormControl(material.mill),
      processor: new UntypedFormControl(material.processor),
      receiptDate: new UntypedFormControl(material.receiptDate),
      qtyClaims: new UntypedFormControl(material.qtyClaims),
      qltClaims: new UntypedFormControl(material.qltClaims),
      site: new UntypedFormControl(material.site),
      available: new UntypedFormControl(material.available),
      availableQtyEa: new UntypedFormControl(material.availableQtyEa),
      availableQtyFt: new UntypedFormControl(material.availableQtyFt),
      allocated: new UntypedFormControl(material.allocated),
      allocatedEA: new UntypedFormControl(material?.allocatedEA),
      allocatedFT: new UntypedFormControl(material?.allocatedFT),
      allocatedNT: new UntypedFormControl(material?.allocatedNT),
      inTransit: new UntypedFormControl(material.inTransit),
      availabilityType: new UntypedFormControl(material.availabilityType),
      transactionId: new UntypedFormControl(material?.transactionId),
      transactionNumber: new UntypedFormControl(material?.transactionNumber),
      classification: new UntypedFormControl(material.classification),
      classificationId: new UntypedFormControl(material.classificationId),
      uom: new UntypedFormControl(material.uom),
      uomId: new UntypedFormControl(material.uomId),
      length: new UntypedFormControl(material.length),
      costEA: new UntypedFormControl(material.costEA),
      cost: new UntypedFormControl(material.cost),
      qty: new UntypedFormControl(material.qty),
      selected: new UntypedFormControl(material.selected),
      inventoryStatus: new UntypedFormControl(material?.inventoryStatus),
      ownerName: new UntypedFormControl(material.material?.owner),
      firstRightOfRefusal: new UntypedFormControl(material.firstRightOfRefusal),
      processingDate: new UntypedFormControl(material.processingDate),
      entryDocumentId: new UntypedFormControl(material.entryDocumentId),
      entryDocumentNumber: new UntypedFormControl(material.entryDocumentNumber),
      vesselName: new UntypedFormControl(material.vesselName)
    });
  }

  getUomFormat(uomId: number) {
    return this.uoms.find(i => i.id === uomId)?.format;
  }

  getLink(id: string): string {
    const startIndex = 0;
    const endIndex = 3;

    return this.links.get(id?.substring(startIndex, endIndex)) || '';
  }

  getTransactionLinks(id: string): string | undefined {
    const prefix = id?.split('-');

    return prefix?.length ? this.transactionLinks.get(prefix[0]) : '';
  }

  getMaterialIDquery(index: number) {
    const control = this.form.at(index);
    const materialNumber = control?.value.material.materialNumber;

    if (materialNumber) {
      return this.utilsService.goToTransactionsHistory(materialNumber);
    }
  }
}
